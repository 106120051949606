const api = {
    'url' : 'https://flags.izeebot.top',
    // 'url' : 'http://0.0.0.0',
    // 'url' : 'http://localhost',
    // 'url' : 'http://127.0.0.1',
    //  'url' : 'http://localhost:8000',
    // 'mode' : 'prod',
    // 'mode' : 'dev'
};

export default api;

